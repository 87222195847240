<template>
    <div>
        <div>
            <div class="content-header container-fluid bg-white">
                <div class="row">
                    <div class="col-sm-4">
                        <h4><i class="ti-shopping-cart-full"> </i> 教师考核 | 绩效考核</h4>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="row">
                    <div class="col-md-12">
                        <el-button type="primary" @click="create()">新建绩效考核</el-button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="w-block" style="padding: 2px;">
                            <!-- <el-select v-model="AssessmentType" placeholder="请选择考核方式">
                                <el-option label="全部" value=""></el-option>
                                <el-option label="自评+考核评" :value="1"></el-option>
                                <el-option label="仅自评" :value="2"></el-option>
                                <el-option label="仅考核评" :value="3"></el-option>
                            </el-select>
                            <el-select v-model="orderType" placeholder="请选择考核排序">
                                <el-option v-if="AssessmentType != 2" label="自评分从高到低" :value="1"></el-option>
                                <el-option v-if="AssessmentType != 2" label="自评分从低到高" :value="2"></el-option>
                                <el-option v-if="AssessmentType != 3" label="考核分从高到低" :value="3"></el-option>
                                <el-option v-if="AssessmentType != 3" label="考核分从低到高" :value="4"></el-option>
                            </el-select> -->

                            <el-input v-model="grid.sea.Key" placeholder="请输入关键字" style="width: 300px;margin-left: 5px">
                                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
                            </el-input>
                        </div>
                        <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total"
                            :page-index="grid.sea.PAGE_INDEX + 1" :page-size="grid.sea.PAGE_SIZE" @page-changed="loadPage">
                            <el-table-column type="index" label="序号" width="50" align="center" />
                            <el-table-column prop="Assessment_Name" label="考核名称" width="250" align="center" />
                            <el-table-column prop="Publish_User_Name" label="发布人" width="100" align="center" />
                            <el-table-column prop="" label="考核方式">
                                <template slot-scope="scope">
                                    {{ scope.row.AssessmentType == 1 ? '自评+考核评' : scope.row.AssessmentType == 2 ? '仅自评'
                                    : '仅考核评' }}
                                </template>
                            </el-table-column>
                            <el-table-column label="发布日期" width="120" align="center">
                                <template slot-scope="scope">
                                    {{ scope.row.Answer_Start_Time | datetime('YYYY-MM-DD') }}
                                </template>
                            </el-table-column>
                            <el-table-column label="结束日期" width="120" align="center">
                                <template slot-scope="scope">
                                    {{ scope.row.Answer_End_Time | datetime('YYYY-MM-DD') }}
                                </template>
                            </el-table-column>

                            <el-table-column prop="Complate1Count" label="完成度" width="100" align="center">
                                <template slot-scope="scope">
                                    {{ ((scope.row.Complate1Count / scope.row.Allcount) * 100).toFixed(0) + "%" }}
                                </template>
                            </el-table-column>

                            <el-table-column label="考核组成员" align="center">
                                <template slot-scope="scope">
                                    <span v-for="(o, i) in scope.row.Man_User_Names" :key="i" style="margin: 0 10px;">
                                        {{ o }} <span v-if="i < scope.row.Man_User_Names.length - 1">,</span>
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column label="操作" width="300" align="center">
                                <template slot-scope="scope">
                                    <el-button type="text" @click="getExport(scope.row.Assessment_Guid)">导出</el-button>
                                    <el-button type="text" icon="el-icon-edit" @click="show(scope.row)">详情</el-button>
                                    <el-button type="text" icon="el-icon-edit"
                                        @click="setMould(scope.row.Assessment_Guid)">设置模板</el-button>
                                    <el-button type="text" icon="el-icon-delete"
                                        @click="rowDel(scope.row.ID)">删除</el-button>
                                </template>
                            </el-table-column>
                        </wgrid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Wgrid from "@/components/wgrid";
    import jsFileDownload from 'js-file-download'
    export default {
        components: { Wgrid },
        data() {
            return {
                grid: {
                    sea: {
                        PAGE_SIZE: 12,
                        PAGE_INDEX: 0,
                        Key: '',
                    },
                    ls: [],
                    total: 0,
                    loading: false,
                },
                AssessmentType: '',
                orderType: ''
            }
        },
        mounted() {
            this.filter()
        },
        methods: {
            filter() {
                this.get()
            },
            loadPage(pi) { 
                this.grid.sea.PAGE_INDEX = pi - 1;
                this.get();
            },
            get() {
                let self = this
                self.grid.loading = true
                this.whale.remote.getCollection({
                    url: "/api/School/PFM/PfmAssessmentSetting/GetPageList",
                    data: self.grid.sea,
                    completed: function (its, n) {
                        console.log(its)
                        self.grid.loading = false;
                        self.grid.ls = its
                        self.grid.total = n
                    }
                })
            },
            create() {
                this.$router.push('/perform/change')
            },
            show(r) {
                this.$router.push({ path: '/perform/info', query: { guid: r.Assessment_Guid } })
            },
            setMould(guid) {
                let self = this
                this.whale.remote.getResult({
                    url: '/api/School/PFM/PfmAssessmentSetting/SetAssessmentToTemplate?Assessment_Guid=' + guid,
                    completed() {
                        self.$message.success('设置成功！')
                    }
                })
            },
            rowDel(Id) {
                let self = this;
                this.$confirm("真的要删除这条记录吗？", "撤消", {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    self.whale.remote.getResult({
                        url: '/api/School/PFM/PfmAssessmentSetting/Delete?Id=' + Id,
                        completed() {
                            self.$message.success('删除成功！')
                            self.filter()
                        }
                    })
                }).catch(() => { })
            },

            getExport(guid) {
                this.whale.remote.getExport({
                    url: '/api/School/PFM/PfmRecord/Export?guid=' + guid,
                    completed: function (m) {
                        console.log('df', m)
                        jsFileDownload(m, '考核记录.xls')
                    }
                })
            }
        },
    }
</script>
<style lang="less" scoped></style>